<template>
  <transition :name="currentAnimation">
    <div class="container-wrapper" v-if="display" @click="clickTarget">
      <div
        class="modal-body relative"
        :class="[ size === 'sm-large' ? 'sm-large' : '']"
        :style="{ background: background }"
      >
        <div
          v-if="!hideClose"
          class="title flex relative p-4"
          :class="[title === '' ? 'justify-end' : 'justify-between',]"
        >
          <slot name="title">
            <p
              v-if="title"
              class="text-black text-lg w-full md:w-auto text-left font-semibold"
            >
              {{ title }}
            </p>
          </slot>
          <img
            v-if="!hideClose"
            class="h-3 w-3 cursor-pointer justify-self-end"
            src="https://res.cloudinary.com/zillaafrica/image/upload/v1623525601/customer/Union_yx6ulk.svg"
            alt="cancel"
            @click="$emit('close')"
          />
        </div>
        <!-- <div class="flex justify-end"> -->

        <div class="content scrollbar pb-5" :class="[padding]"><slot /></div>
        <div class="footer">
          <slot name="footer"></slot>
        </div>
      </div>
      </div>
    <!-- </div> -->
  </transition>
</template>
<script>
export default {
  props: {
    display: {
      type: Boolean,
      default: false,
      required: true,
    },
    hideClose: {
      type: Boolean,
      default: false,
      required: false,
    },
    title: {
      type: String,
      default: "",
    },
    background: {
      type: String,
      default: "#f6f7ff",
      required: false,
    },
    padding: {
      type: String,
      default: "p-4",
    },
    size: {
      type: String,
      default: "",
    },
  },
  mounted(){
    // let vh = window.innerHeight * 0.01;
    //   document.documentElement.style.setProperty('--vh', `${vh}px`);
 // We listen to the resize event
    // window.addEventListener('resize', () => {
    //   // We execute the same script as before
    //   let vh = window.innerHeight * 0.01;
    //   document.documentElement.style.setProperty('--vh', `${vh}px`);
    // });
  },
  methods: {
    clickTarget(e) {
      if (e.target.className.includes("container-wrapper")) {
        this.$emit("close");
      }
    },
  },
  computed: {
    currentAnimation() {
      if (window.matchMedia("(max-width: 1024px)").matches) {
        return "";
      } else {
        return "fade";
      }
    },
  },
  watch: {
    display(value) {
      if (value) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
  },
};
</script>
<style scoped>
@import "~@/assets/styles/animation.css";
.container-wrapper {
  position: fixed !important;
  top: 0;
  left: 0;
  bottom:0;
  height: 100%;
  width: 100%;
  background-color: rgb(76, 74, 82, 0.7);
  display: grid;
  place-items: center;
  z-index: 10;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
img {
  /* position: absolute; */
  right: 2rem;
  top: 2rem;
}
.title {
  flex-shrink: 0;
}
.content {
  flex-grow: 1;
  overflow: auto;
  min-height: 2em;
}
.footer{
  flex-shrink: 0;
  border-top: 1px solid #EDEDF0
}
/* .info {
  position: absolute;
  bottom: 1rem;
  width: 100%;
  left: 0;
  padding: 0px 10px;
} */
.modal-body {
  /* background-color: #f6f7ff; */
  /* position: relative; */
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  position:fixed;
  top: 0;
  bottom: 0;
}
@media (min-width: 1024px) {
  .modal-body {
    width: 53.125rem;
    /* margin: auto; */
    border-radius: 10px;
    height: 80vh;
    min-height: initial;
    overflow-y: auto;
    max-height: 723px;
    position: initial
  }
  .sm-large {
    max-width: 36rem;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s cubic-bezier(0.45, 0.25, 0.6, 0.95);
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  transform: translate(0%, -10px);
  opacity: 0;
}
</style>

