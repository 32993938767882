var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: _vm.currentAnimation } }, [
    _vm.display
      ? _c(
          "div",
          { staticClass: "container-wrapper", on: { click: _vm.clickTarget } },
          [
            _c(
              "div",
              {
                staticClass: "modal-body relative",
                class: [_vm.size === "sm-large" ? "sm-large" : ""],
                style: { background: _vm.background },
              },
              [
                !_vm.hideClose
                  ? _c(
                      "div",
                      {
                        staticClass: "title flex relative p-4",
                        class: [
                          _vm.title === "" ? "justify-end" : "justify-between",
                        ],
                      },
                      [
                        _vm._t("title", function () {
                          return [
                            _vm.title
                              ? _c(
                                  "p",
                                  {
                                    staticClass:
                                      "text-black text-lg w-full md:w-auto text-left font-semibold",
                                  },
                                  [_vm._v(" " + _vm._s(_vm.title) + " ")]
                                )
                              : _vm._e(),
                          ]
                        }),
                        !_vm.hideClose
                          ? _c("img", {
                              staticClass:
                                "h-3 w-3 cursor-pointer justify-self-end",
                              attrs: {
                                src: "https://res.cloudinary.com/zillaafrica/image/upload/v1623525601/customer/Union_yx6ulk.svg",
                                alt: "cancel",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("close")
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      2
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "content scrollbar pb-5",
                    class: [_vm.padding],
                  },
                  [_vm._t("default")],
                  2
                ),
                _c("div", { staticClass: "footer" }, [_vm._t("footer")], 2),
              ]
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }